.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // gap: 10px;
    // grid-auto-rows: 200px;
    grid-template-areas: ". a .";
}

#card-body{
    // justify-content: center;
    // align-items: center;
    // text-align: center;
    grid-area: a;
    align-self: center;
    justify-self: center;
}
.card-game{
    justify-content: center;
}
