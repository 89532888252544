
/* .carousel .thumbs{
    display: none;
}
.carousel .button{
    display: none;
}*/

.carousel .thumb{
   margin: 0 5px;
   cursor: pointer;
}
.carousel .slide .img{
    width: 100vw !important;
    height: 80vh;
    border-radius: 16px;
}

@media only screen and (max-width: 600px) {
    .carousel .slide .img{
        width: 100vw;
        height: 20vh;
        object-fit: cover;
    }
}