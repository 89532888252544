// Boilerplate
@media only screen and (min-width: 700px) {
  * {
    margin: 0;
    padding: 0;
    //overflow-y: hidden;
  }
  
  // Page
  .page {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 'headerWrapper' 'main' 'Footer';
    height: 100vh;
    overflow-y: hidden; 
  
    &.has-sidebar {
      grid-template-columns: minmax(auto, 262px) auto;
      grid-template-areas: 'headerWrapper headerWrapper' 'aside-left main' 'aside-left Footer';
    }
  }
  
  .page-header {
    grid-area: headerWrapper;
    // padding: 10px;
    background-color: #999;
  }
  
  .page-aside {
    grid-area: aside-left;
    padding: 10px;
    background-color: #bbb;
  }
  
  .page-main {
    overflow-y:hidden;
    grid-area: main;
    position: relative;
  }
  
  .page-footer {
    grid-area: Footer;
    background-color: #ddd;
  }
  
  // Grid
  .grid {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    padding-bottom: 10%;
    
  }
}
  
  @media only screen and (max-width: 600px) {
    * {
      margin: 0;
      padding: 0;
      //overflow-y: hidden;
    }
    .page {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: 1fr auto;
      grid-template-areas: 'main' 'Footer';
      height: 100vh;
      overflow-y: auto;
      &.has-sidebar {
        grid-template-columns: 1fr auto;
        grid-template-areas: 'main' 'Footer';
      }
    }
   
    .page-main {
      grid-area: main;
      position: relative;
      overflow-y: hidden;
    }
    
    .page-footer {
      grid-area: Footer;
      background-color: #ddd;
    }
    
    // Grid
    .grid {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10%;
      padding-bottom: 10%;
      
    }
  }